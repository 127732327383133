import {apiRequest} from '@core/http.ts';
import {log} from '@core/utils/dev.js';
import {AxiosError, AxiosInstance, AxiosResponse} from 'axios';
import {useMessage} from 'naive-ui';
import {computed, MaybeRef, Ref, ref, unref} from 'vue';
import {isObject} from "@core/utils/is/index.ts";
import {useAsyncCallback} from "@core/composable/useAsyncCallback.ts";

interface FormItemPropInvalid {
  path: string,
  validationStatus: 'error',
  feedback: string,
}

interface FormItemPropValid {
  path: string,
  showFeedback: false,
}

type ErrorWithMessage = AxiosError<{ message: string }>;
type ErrorWithValidationErrors = AxiosError<{ errors: { [p: string]: string[] }[] }>;

type PayloadData<T> = undefined | null | object | T | MaybeRef<object> | ((...args: any[]) => object | T);
export type FormRequestMethod = 'POST' | 'PUT' | 'PATCH';
export type AllowedMethod = FormRequestMethod | 'DELETE' | 'GET';

export interface UseRequestParam<R extends any> {
  url: string | (() => string),
  method?: AllowedMethod,
  defaultMessages?: { success?: string, failed?: string },
  postSuccess?: ((response: AxiosResponse<R>, ...any: any[]) => void),
  postFailed?: ((error: AxiosError, ...any: any[]) => void),
  request?: AxiosInstance | undefined
}

export interface UseFormRequestParam<T extends any, R extends any> extends UseRequestParam<R> {
  method?: FormRequestMethod,
  data: PayloadData<T>,
}

type UseRequestResult = {
  execute: (...args: any[]) => Promise<void>,
  initialize: (...args: any[]) => Promise<void>,
  isPending: Ref<boolean>,
  isInitialized: Ref<boolean>
}
type UseFormRequestResult = ReturnType<typeof useValidationError> & {
  execute: (...args: any[]) => Promise<void>,
  isPending: Ref<boolean>
}

export const useAxiosError = (defaultMessage: string|null = null): { notify: (e: ErrorWithMessage) => void } => {
  const notification = useMessage();

  const notify = (e: ErrorWithMessage) => {
    // log(e);

    if (!isObject(e) || !(e instanceof AxiosError) || e?.response?.status === 422) {
      return;
    }

    try {
      const message = e.response?.data?.message ?? defaultMessage ?? null;

      message && notification.error(message);

    } catch {
    }
  };

  return {
    notify,
  };
};

const useValidationError = () => {
  const validationErrors = ref<{ [p: string]: string | null | undefined }>({});

  const setError = (key: string, message = null) => {
    if (!key) {
      return;
    }

    validationErrors.value[key] = message ?? null;
  };

  const clearError = (key = null) => {
    if (key) {
      setError(key);
      return;
    }

    validationErrors.value = {};
  };

  const fromAxiosError = (e: ErrorWithValidationErrors) => {
    clearError();

    if (!(e instanceof AxiosError) || e?.response?.status !== 422) {
      return;
    }

    try {
      validationErrors.value = Object.entries(e.response.data?.errors ?? {}).reduce((errors, [field, error]) => {
        errors[field] = Array.isArray(error) ? error[0] : error;

        return errors;
      }, {});
    } catch (e) {
      log(e);
    }
  };

  const formItemProp = computed(() => (path: string): FormItemPropValid | FormItemPropInvalid => {
    let feedback = unref(validationErrors)[path];

    if (feedback) {
      return {
        path,
        feedback,
        validationStatus: 'error',
      };
    }

    return {
      path,
      showFeedback: false,
    };
  });

  return {
    validationErrors,
    formItemProp,
    fromAxiosError,
    setError,
    clearError,
  };
};

export function useFormRequest<D extends any, R extends { message: string | undefined }>({
                                                                                           url,
                                                                                           method = 'POST',
                                                                                           data = null,
                                                                                           defaultMessages,
                                                                                           postSuccess,
                                                                                           postFailed,
                                                                                           request
                                                                                         }: UseFormRequestParam<D, R>): UseFormRequestResult {
  const isPending = ref(false);
  const notification = useMessage();
  const validationError = useValidationError();
  const axiosError = useAxiosError(defaultMessages?.failed ?? null);

  const req = request ?? apiRequest;

  const execute = async (...args: any[]) => {
    if (isPending.value) {
      return;
    }

    isPending.value = true;

    try {
      const response: AxiosResponse<R> = await req({
        url: typeof url === 'function' ? url() : url,
        method,
        // @ts-ignore
        data: typeof unref(data) === 'function' ? unref(data)(...args) : data,
      });


      const message = response.data?.message ?? defaultMessages?.success ?? null;

      message && notification.success(message);

      if (typeof postSuccess === 'function') {
        postSuccess?.(response, ...args);
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        validationError.fromAxiosError(e);
        axiosError.notify(e);

        if (typeof postFailed === 'function') {
          postFailed?.(e, ...args);
        }
      } else {
        console.warn(e);
      }
    } finally {
      isPending.value = false;
    }
  };

  return {
    isPending,
    execute,
    ...validationError,
  };
}

export function useRequest<R extends { message: string | undefined }>({
                                                                        url,
                                                                        method = 'POST',
                                                                        postSuccess,
                                                                        postFailed,
                                                                        defaultMessages,
                                                                        request
                                                                      }: UseRequestParam<R>): UseRequestResult {
  const isPending = ref(false);
  const isInitialized = ref(false);
  const requestError = useAxiosError(defaultMessages?.failed ?? null);
  const notification = useMessage();

  const req = request ?? apiRequest;

  const execute = async (...args: any[]) => {
    if (isPending.value) {
      return;
    }

    isPending.value = true;

    try {
      const response: AxiosResponse<R> = await req({
        /* @ts-ignore */
        url: typeof url === 'function' ? url(...args) : url,
        method,
      });

      const message = response.data?.message ?? defaultMessages?.success ?? null;

      if (message) {
        notification.success(message);
      }

      if (typeof postSuccess === 'function') {
        postSuccess?.(response, ...args);
      }

      isInitialized.value = true;
    } catch (e) {
      requestError.notify(e as ErrorWithMessage);

      if (e instanceof AxiosError) {
        if (typeof postFailed === 'function') {
          postFailed?.(e, ...args);
        }
      } else {
        log('Bug::', e);
      }

    } finally {
      isPending.value = false;
    }
  };

  const initialize = async (...args: any[]) => {
    if (!isInitialized.value) {
      await execute(...args);
    }
  };

  return {
    isInitialized,
    initialize,
    isPending,
    execute,
  };
}

export interface UseAsyncDataParams {
  url: string | (() => string),
  getParams?: () => Record<string, string|number|null|undefined|Array<string|number>|object>,
  method?: AllowedMethod,
}

export function useAsyncData({url, getParams}: UseAsyncDataParams) {
  const notification = useMessage();
  const response = ref();
  const isInitialized = ref(false);

  const buildParams = (params = {}) => {
    return {
      ...(unref(getParams)?.() ?? {}),
      ...(params ?? {}),
    };
  };

  const {isLoading: isPending, execute} = useAsyncCallback(async (params = {}) => {
    try {
      const __url = unref(url)
      // @ts-ignore
      const _url = typeof __url === 'function' ? __url() : String(__url);

      const {data} = await apiRequest.get(_url, {params: buildParams(params)});

      response.value = data;

      isInitialized.value = true;
    } catch (e) {
      notification.error('Error fetching resource!');
    }
  });

  const initialize = () => {
    if (isInitialized.value) {
      return;
    }

    execute?.();
  };

  return {
    isPending,
    isInitialized,
    response,
    execute,
    initialize,
  };
}



